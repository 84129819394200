<template>
  <v-container fluid class="content-wrapper">
      <div class="row match-height">
    <h4 class="fw-600 text-mb-2">
      {{ $t("SigninUserAndRole.title_create_group") }}
    </h4>
        <div class="col-12 pb-1 px-5">
          <form class="form form-horizontal">
            <div class="form-body">
              <div class="row">
                <label
                  class="col-md-2 col-xl-1 px-0 label-control text-left"
                  for="projectinput1"
                  >Group name :
                </label>
                <div class="col px-0 px-md-1">
                  <input
                    type="text"
                    id="projectinput1"
                    class="form-control"
                    placeholder="Super admin"
                    name="fname"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <label
                  class="col-md-2 col-xl-1 px-0 label-control text-left"
                  for="projectinput1"
                  >Status :
                </label>
                <div class="col px-0 px-md-1">
                  <div class="form-group">
                    <select class="form-control" id="">
                      <option>Enable</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-12">
          <!-- Recent Booking -->
          <div class="row">
            <div id="recent-transactions" class="col-12">
              <div class="card">
                <div class="card-content p-2">
                  <div class="table-responsive">
                    <table
                      id="recent-orders"
                      class="
                        table table-white-space
                        row-grouping
                        display
                        no-wrap
                        icheck
                        table-middle
                        text-center
                        dataTable
                      "
                      role="grid"
                      aria-describedby="users-contacts_info"
                    >
                      <thead class="create_group">
                        <tr role="row">
                          <th
                            class="sorting border-top-0 text-center"
                            tabindex="0"
                            aria-controls="users-contacts"
                            rowspan="1"
                            colspan="1"
                            aria-label=": activate to sort column ascending"
                            style="position: inherit"
                          >
                            <div
                              class="
                                custom-control custom-checkbox
                                text-uppercase
                              "
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="checkboxsmallall"
                              />
                              <label
                                class="custom-control-label"
                                for="checkboxsmallall"
                                >All</label
                              >
                            </div>
                          </th>
                          <th
                            class="
                              sorting
                              border-top-0
                              text-center text-uppercase
                            "
                            tabindex="0"
                            aria-controls="users-contacts"
                            rowspan="1"
                            colspan="1"
                            aria-sort="descending"
                            aria-label="Name: activate to sort column ascending"
                            style="width: 149.797px"
                          >
                            User Detail
                          </th>
                          <th
                            class="
                              sorting
                              border-top-0
                              text-center text-uppercase
                            "
                            tabindex="0"
                            aria-controls="users-contacts"
                            rowspan="1"
                            colspan="1"
                            aria-label="Email: activate to sort column ascending"
                            style="width: 159.984px"
                          >
                            Role
                          </th>
                          <th
                            class="
                              sorting
                              border-top-0
                              text-center text-uppercase
                            "
                            tabindex="0"
                            aria-controls="users-contacts"
                            rowspan="1"
                            colspan="1"
                            aria-label="Phone: activate to sort column ascending"
                            style="width: 87.0625px"
                          >
                            Setting
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr role="row" class="">
                          <td style="width: 21px; text-align: center">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="checkboxsmall14"
                              />
                              <label
                                class="custom-control-label"
                                for="checkboxsmall14"
                              ></label>
                            </div>
                          </td>
                          <td class="user-media text-truncate text-center">
                            <div class="media">
                              <div class="media-left">
                                <img
                                  class="media-object avatar avatar-lg mr-1"
                                  src="app-assets/images/portrait/small/avatar-s-5.png"
                                  alt="Generic placeholder image"
                                />
                              </div>
                              <div class="media-body text-left">
                                <b>User Name</b> <br />
                                test@gmail.com
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            <b>Addmin</b>
                          </td>
                          <td class="text-center">
                            <i class="ft-settings font-medium-3"></i>
                          </td>
                        </tr>
                        <tr role="row" class="">
                          <td style="width: 21px; text-align: center">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="checkbox1"
                              />
                              <label
                                class="custom-control-label"
                                for="checkbox1"
                              ></label>
                            </div>
                          </td>
                          <td class="user-media text-truncate text-center">
                            <div class="media">
                              <div class="media-left">
                                <img
                                  class="media-object avatar avatar-lg mr-1"
                                  src="app-assets/images/portrait/small/avatar-s-5.png"
                                  alt="Generic placeholder image"
                                />
                              </div>
                              <div class="media-body text-left">
                                <b>User Name</b> <br />
                                test@gmail.com
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            <b>Addmin</b>
                          </td>
                          <td class="text-center">
                            <i class="ft-settings font-medium-3"></i>
                          </td>
                        </tr>
                        <tr role="row" class="">
                          <td style="width: 21px; text-align: center">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="checkbox2"
                              />
                              <label
                                class="custom-control-label"
                                for="checkbox2"
                              ></label>
                            </div>
                          </td>
                          <td class="user-media text-truncate text-center">
                            <div class="media">
                              <div class="media-left">
                                <img
                                  class="media-object avatar avatar-lg mr-1"
                                  src="app-assets/images/portrait/small/avatar-s-5.png"
                                  alt="Generic placeholder image"
                                />
                              </div>
                              <div class="media-body text-left">
                                <b>User Name</b> <br />
                                test@gmail.com
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            <b>Addmin</b>
                          </td>
                          <td class="text-center">
                            <i class="ft-settings font-medium-3"></i>
                          </td>
                        </tr>
                        <tr role="row" class="">
                          <td style="width: 21px; text-align: center">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="checkbox3"
                              />
                              <label
                                class="custom-control-label"
                                for="checkbox3"
                              ></label>
                            </div>
                          </td>
                          <td class="user-media text-truncate text-center">
                            <div class="media">
                              <div class="media-left">
                                <img
                                  class="media-object avatar avatar-lg mr-1"
                                  src="app-assets/images/portrait/small/avatar-s-5.png"
                                  alt="Generic placeholder image"
                                />
                              </div>
                              <div class="media-body text-left">
                                <b>User Name</b> <br />
                                test@gmail.com
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            <b>Addmin</b>
                          </td>
                          <td class="text-center">
                            <i class="ft-settings font-medium-3"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-warning btn-min-width mb-1 mr-3"
                    >
                      ย้อนกลับ
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-min-width mb-1"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninCreateGroupScreen",
  metaInfo: {
    title: "Create Group",
    titleTemplate: "%s | Aigent",
  },
};
</script>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
